<template>
  <div class="my-6 text-start">
    <h3 class="font-bold">Lista de {{ route.name }}</h3>
    <span class="text-sm text-gray-500"
      >Aqui você pode observar todos os itens relacionados às {{ route.name.toLowerCase() }} como
      suas respectivas ações.
    </span>
  </div>
    <div class="flex flex-rowmt-5 items-center" >
    <div class="w-full p-2">
      <Input
        type="datalist"
        id="evento"
        v-model="eventoId"
        label="Evento"
        placeholder="Selecione um evento"
        :data="eventos"
        @keyupSearch="getEvent"
      />
    </div>
   <div class="flex mb-6 justify-center mt-3">
      <Button color="primary" :text="`Buscar`" @click="getEvent" />
    </div>
  </div>
  <hr class="my-9" />  
 
  <div class="card shadow-lg border rounded-lg p-8 bg-white mt-10">
    <div class="w-full">
      <div class="flex-3 w-full text-start">
        <h3 class="font-bold">Informações de vendas</h3>
        <span class="text-sm text-gray-500">
          Aqui você pode observar todos os dados relacionados as vendas do
          evento selecionado.
        </span>
      </div>
      <div class="flex mb-6 justify-end mt-3">
        <Button color="primary" :text="`Gerar Relatorio Vendas`"  v-if="eventoId" @click="geraRelatorio()"/>

        <router-link to="/vendas/criar">
          <Button color="primary" :text="`Criar nova venda`" />
        </router-link>
      </div>
      <div class="card" v-if="data">
        <DataTable
          :headers="headers"
          :data="data"
          :options="options"
          color="primary"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from "../../components/Elements/Datatable.vue";
import Button from "../../components/Elements/Button.vue";
import Input from '@/components/Elements/Input.vue';
import { inject, onMounted, ref } from "vue";
import { GET, DELETE } from "../../services/api";
import { useRoute, useRouter } from "vue-router";
import {toDate} from "../../services/formater"
import moment from 'moment-timezone';

export default {
  name: "Vendas",
  components: {
    DataTable,
    Button,
    Input,
  },
  setup() {
    const headers = [
      { key: "Id", value: "id" },
      { key: "Comprador", value: "comprador" },
      { key: "Email", value: "email" },
      { key: "Documento", value: "documento"},
      { key: "Nº Pedido", value: "identificador"},
      { key: "Status", value: 'status'},
      { key: "Options", value: "" },
    ];

    const route = useRoute();
    const eventoId = ref("");
    const router = useRouter();
    const modal = inject("modal");
    const alert = inject("alert");
    const loader = inject("loading");
    const data = ref([]);
    const eventos = ref([]);

    onMounted(async () => {
      try {
        loader.open();
        const response = await GET("event");
        response.map( evento => eventos.value.push({ label: evento.nome +" - "+ toDate(evento.data), value: evento.id }));

        if(  localStorage.getItem("event_santo_cartao") != 'null' ){
            const [eventoSelecionado] = eventos.value.filter(
            (evento) => evento.value == localStorage.getItem("event_santo_cartao")
          );

          const temporario = await GET(`vendas/${eventoSelecionado.value}/evento`);
       

        temporario.map(prod => {

          if(prod.estado == null)
          {
            prod.status = 'Ativo'
          }else
          {
            prod.status = 'Cancelado'
          }

        })
        data.value = temporario;


        if(data.value.length <= 0)
          alert.open("Atenção!", `Não há vendas relacionados a esse evento!`, "warning");
       
          eventoId.value = eventoSelecionado.label;

        }
        
        loader.close();
      } catch (e) {
        loader.close();
        alert.open("Erro", e, "danger");
      }
    });

     const getEvent = async () => {
      loader.open();
      
      try {
        const [eventoSelecionado] = eventos.value.filter(
          (evento) => evento.label == eventoId.value
        );

        eventoId.value = eventoSelecionado.value;

        localStorage.setItem("event_santo_cartao", eventoId.value);
       


        const temporario = await GET(`vendas/${eventoId.value}/evento`);
       

        temporario.map(prod => {         

          if(prod.estado == null)
          {
            prod.status = 'Ativo'
          }else
          {
            prod.status = 'Cancelado'
          }

        })
        data.value = temporario;


        if(data.value.length <= 0)
          alert.open("Atenção!", `Não há vendas relacionados a esse evento!`, "warning");
       
         eventoId.value = eventoSelecionado.label;

        
      } catch (e) { 
 
        alert.open("Erro", "Um erro ocorreu, tente novamente", "danger");
        eventoId.value = null;
         
      } 
      loader.close();
    }

  function sendMessageWhatsApp(venda){
    try {
      if(!venda.telefone){
        alert.open("Atenção", "Essa venda não possui telefone", "warning");
        return
      }

      const msg =  `Sua compra de número <b>${venda.identificador}</b>, enviado na data ${moment.tz(venda.data,'America/Sao_Paulo').format('DD/MM/YYYY HH:mm:ss')}, acabou de ser cancelado pelo motivo: ${venda.motivo} Portanto não é mais válido para entrada no mesmo.`

      const url = `https://web.whatsapp.com/send?phone=${venda.telefone}&text=${encodeURIComponent(msg)}`;
      window.open(url, '_blank');


    } catch (error) {
      alert.open("Erro", "Um erro ocorreu, tente novamente", "danger");
    }
  }

  const options = [
      { icon: "pen", path: '/vendas/editar' , title: "Editar venda" },
      { icon: "whatsapp", action: (param) => sendMessageWhatsApp(param), title: "Enviar mensagem Whats App"},

    ];


    const geraRelatorio = async ()=>{

      loader.open();

      const [eventoSelecionado] = eventos.value.filter(
          (evento) => evento.label == eventoId.value
        );

      const evento = eventoSelecionado.value;


      const response = await GET(`/vendas/${evento}/relatorio_venda`)

      fetch(`https://santocartao-files.s3.amazonaws.com/${response}`)
          .then(resp => resp.blob())
          .then(blob => {
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.style.display = 'none';
              a.href = url;
              // the filename you want
              a.download = 'relatorioVenda.xlsx'
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(url);
              loader.close();
          })
          .catch((error) =>{console.log(error); loader.close()});

        
    }

    return { headers, data, options, route, getEvent, eventoId,eventos , geraRelatorio };
  },
};
</script>

<style>
</style>